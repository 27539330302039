import { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { StudentMilestoneCardsStyles } from './StudentMilestoneCardsStyle';
import { roundNumber } from '../../../../constants';
import {
  Box,
  Card,
  CardContent,
  CardActionArea,
  Container,
  Grid,
  Typography,
} from '@material-ui/core';
import Loader from '../../../../components/Loader';
import client from '../../../../apollo';
import gql from 'graphql-tag';

// interface type of the FC component
interface Props {
  StudentId: number | null | undefined
}

// interface type of the API fetched data
interface fetchedData {
  milestone: string,
  totalCalculatedLoggedHours: number,
  lastActivityDate: string,
  lastMSTActivityDate: string
}

/**
 * gql query to get the calculated total log hours for each milestone 
 * and respective activity date(s)
 */

export const GET_MILESTONE_TIME_LOG_HOURS_DATE = gql`
  query getMilestoneTimeLogHoursDateQuery($StudentId: Int!) {
    getMilestoneTimeLogHoursDate(StudentId: $StudentId) {
      milestone,
      totalCalculatedLoggedHours,
      lastActivityDate,
      lastMSTActivityDate
    }
  }
`

// main FC component
const StudentMilestoneCards: FC<Props> = ({ StudentId }) => {
  // CSS
  const classes = StudentMilestoneCardsStyles();
  const check = true;
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [fetchedData, setFetchedData] = useState<Array<fetchedData>>([]);

  const handleGetMilestoneTimeLogHoursDate = async () => {
    // try-catch to make gql api call
    try {
      const response = await client.query({
        query: GET_MILESTONE_TIME_LOG_HOURS_DATE,
        variables: {
          StudentId
        },
        fetchPolicy: 'network-only'
      });

      // if there is any response get the fetched data 
      if (response) {
        setFetchedData(response?.data?.getMilestoneTimeLogHoursDate);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log('Error while making a call to the GraphQL Query: GET_MILESTONE_TIME_LOG_HOURS_DATE', error);
    }
  }

  useEffect(() => {
    handleGetMilestoneTimeLogHoursDate();
  }, []);

  // function to redirect to Time Logs page
  const handleCardClick = (milestone: string) => {
    history.push({
      pathname: `${history.location.pathname}?_t=timeLogs`,
      state: {
        milestone: milestone
      }
    });
  }

  return (
    <>
      {
        isLoading && <Loader />
      }
      <Container className={classes.container}>
        {/* Grid Header */}
        <Grid className={classes.header}>
          <Typography variant='h4' className={classes.milestoneTabHeading}>
            Milestones
          </Typography>
          <Typography className={classes.milestoneTabSubHeading}>
            Student progress, as declared in activities recorded in Time Logs. Click the milestone below for more details.
          </Typography>
        </Grid>
        {/* Grid Cards Block where milestone is Orientation */}
        <Grid container spacing={2} className={classes.milestoneCards}>
          {/* There would be a card only for Orientation milestone  */}
          {
            fetchedData.map((milestone, index) => {
              return (
                milestone?.milestone === 'Orientation' ?
                  (
                    <Grid item key={index} xs={12} sm={6}>
                      <Card variant='outlined' className={milestone?.totalCalculatedLoggedHours ? `${classes.cards} ${classes.changeCardBackgroud}` : classes.cards}>
                        <CardActionArea onClick={() => handleCardClick(milestone?.milestone)}>
                          <CardContent>
                            <Box className={classes.boxTypography}>
                              <Typography variant='h5' className={classes.mstCardHeading}>
                                {milestone.milestone}
                              </Typography>
                              <Typography variant='body1' className={classes.mstCardHours}>
                                {
                                  milestone?.totalCalculatedLoggedHours ?
                                    roundNumber(milestone?.totalCalculatedLoggedHours, 1) > 1 ?
                                      `${roundNumber(milestone?.totalCalculatedLoggedHours, 1)} hours logged`
                                      :
                                      `${roundNumber(milestone?.totalCalculatedLoggedHours, 1)} hour logged`
                                    :
                                    '--'
                                }
                              </Typography>
                            </Box>
                            <Box mb={1} className={classes.mstCardDates}>
                              <Typography variant='body2'>
                                <span>Last activity:</span>
                              </Typography>
                              <Typography variant='body2'>
                                {
                                  milestone?.lastActivityDate !== 'January 01, 1800' ?
                                    milestone?.lastActivityDate
                                    :
                                    '--'
                                }
                              </Typography>
                            </Box>
                            <Box className={classes.mstCardDates}>
                              <Typography variant='body2'>
                                <span>Last MST activity:</span>
                              </Typography>
                              <Typography variant='body2'>
                                {
                                  milestone?.lastMSTActivityDate !== 'January 01, 1800' ?
                                    milestone?.lastMSTActivityDate
                                    :
                                    '--'
                                }
                              </Typography>
                            </Box>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ) : ''
              )
            })
          }
        </Grid>
        {/* Grid Cards Block where milestone is not Orientation */}
        <Grid container spacing={2} className={classes.milestoneCards}>
          {/* There would be a card for each milestone */}
          {
            fetchedData.map((milestone, index) => {
              return (
                milestone?.milestone !== 'Orientation' ?
                  (
                    <Grid item key={index} xs={12} sm={6}>
                      <Card variant='outlined' className={milestone?.totalCalculatedLoggedHours ? `${classes.cards} ${classes.changeCardBackgroud}` : classes.cards}>
                        <CardActionArea onClick={() => handleCardClick(milestone?.milestone)}>
                          <CardContent>
                            <Box className={classes.boxTypography}>
                              <Typography variant='h5' className={classes.mstCardHeading}>
                                {milestone?.milestone}
                              </Typography>
                              <Typography variant='body1' className={classes.mstCardHours}>
                                {
                                  milestone?.totalCalculatedLoggedHours ?
                                    roundNumber(milestone?.totalCalculatedLoggedHours, 1) > 1 ?
                                      `${roundNumber(milestone?.totalCalculatedLoggedHours, 1)} hours logged`
                                      :
                                      `${roundNumber(milestone?.totalCalculatedLoggedHours, 1)} hour logged`
                                    :
                                    '--'
                                }
                              </Typography>
                            </Box>
                            <Box mb={1} className={classes.mstCardDates}>
                              <Typography variant='body2'>
                                <span>Last activity:</span>
                              </Typography>
                              <Typography variant='body2'>
                                {
                                  milestone?.lastActivityDate !== 'January 01, 1800' ?
                                    milestone?.lastActivityDate
                                    :
                                    '--'
                                }
                              </Typography>
                            </Box>
                            <Box className={classes.mstCardDates}>
                              <Typography variant='body2'>
                                <span>Last MST activity:</span>
                              </Typography>
                              <Typography variant='body2'>
                                {
                                  milestone?.lastMSTActivityDate !== 'January 01, 1800' ?
                                    milestone?.lastMSTActivityDate
                                    :
                                    '--'
                                }
                              </Typography>
                            </Box>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ) : ''
              )
            })
          }
        </Grid>
      </Container>
    </>
  )
};

export default StudentMilestoneCards;
