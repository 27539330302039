import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Colors from '../../../../common/colors';
import Fonts from '../../../../common/fonts';

export const StudentMilestoneCardsStyles = makeStyles((theme: Theme) =>
  createStyles({

    container: {
      margin: 0,
      padding: 0,
    },

    header: {
      borderBottom: `1px solid ${Colors.WHITE_SEVEN}`,
    },

    milestoneTabHeading: {
      fontFamily: Fonts.POPPINS_FONT,
      fontWeight: 600,
      fontSize: 24,
      color: Colors.BLACK_TWELVE,
      marginBottom: 6,
      paddingBottom: 6,

      [theme.breakpoints.down('xs')]: {
        fontSize: 18,
      },
    },

    milestoneTabSubHeading: {
      fontFamily: Fonts.INTER,
      fontWeight: 400,
      fontSize: 14,
      color: Colors.BLACK_FOURTEEN,
      marginBottom: 6,
      paddingBottom: 6,
    },

    milestoneCards: {
      marginTop: 10,
      
      [theme.breakpoints.between('lg', 'xl')]: {
        width: 980
      },
    },

    cards: {
      borderRadius: 10,
    },

    changeCardBackgroud: {
      backgroundColor: Colors.WHITE_EIGHT,
      border: 'none',

      '& :hover': {
        backgroundColor: Colors.BLUE_OPACITY,
      },
    },

    boxTypography: {
      display: 'flex',
      justifyContent: 'space-between'
    },

    mstCardHeading: {
      fontFamily: Fonts.INTER,
      color: Colors.BLACK_TWELVE,
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 8,
      paddingBottom: 8,

      [theme.breakpoints.down('xs')]: {
        fontSize: 18,
      },
    },

    mstCardHours: {
      fontFamily: Fonts.INTER,
      color: Colors.BLACK_THIRTEEN,
      fontSize: 14,
      fontWeight: 400,
    },

    mstCardDates: {
      fontFamily: Fonts.INTER,
      color:Colors.BLACK_FOURTEEN,
      display: 'flex',

      '& span': {
        display: 'block',
        textAlign: 'left',
        width: 120,
      },
    },
  })
);