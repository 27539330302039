import { FC } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import Colors from '../common/colors';
import Fonts from '../common/fonts';
import { SVG_PATH1, SVG_PATH2, SVG_PATH3 } from '../constants';
import { makeStyles } from '@material-ui/core/styles';

export const useTooltipStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: Colors.BLACK_THREE,
    boxShadow: `0px 2px 10px ${Colors.BOX_SHADOW_THREE}`,
    borderRadius: 3,
    padding: '16px 20px 20px',
    fontFamily: Fonts.POPPINS_FONT,
    fontSize: 16,
    lineHeight: '20px',
    letterSpacing: '0.3375px',
    color: Colors.WHITE_ONE,
    maxWidth: 300,
  },
}));

export const useProfileTooltipStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: Colors.BLACK_THREE,
    boxShadow: `0px 2px 10px ${Colors.BOX_SHADOW_THREE}`,
    borderRadius: 3,
    padding: '8px 12px 8px 12px',
    fontFamily: Fonts.INTER,
    fontSize: 12,
    lineHeight: '20px',
    letterSpacing: '-0.6%',
    color: Colors.WHITE_SEVEN,
    // maxWidth: 300,
    fontWeight: 400,
  },
}));

const CustomTooltip = ({
  message,
  children,
}: {
  message: string;
  children?: any;
}): JSX.Element => {
  const tooltipClasses = useTooltipStyles();
  return (
    <>
      <Tooltip classes={tooltipClasses} title={message}>
        {children ? (
          <span>{children}</span>
        ) : (
          <IconButton aria-label="Delete" className="cautionIcon">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d={SVG_PATH1} />
              <path d={SVG_PATH2} />
              <path d={SVG_PATH3} />
            </svg>
          </IconButton>
        )}
      </Tooltip>
    </>
  );
};

interface PropsType {
  title?: any;
}

export const CustomTooltipComponent: FC<PropsType> = ({ children, title }) => {
  const tooltipClasses = useTooltipStyles();

  return (
    <Tooltip classes={tooltipClasses} title={title}>
      <span style={{ cursor: 'pointer' }}>{children}</span>
    </Tooltip>
  );
};

export default CustomTooltip;
