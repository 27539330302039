import { Divider, Grid, Typography, Box } from '@material-ui/core';

import { Query } from 'react-apollo';

import { CHECK_STUDENT_PROFILE } from  './graphql/queries/studentProfilePage'
import { getProfileData } from './graphql/queries/studentProfilePage/__generated__/getProfileData';

import LoaderVariant2 from '../../../components/LoaderVariant2';
import StudentProfileHeader from '../../../components/layout/StudentProfileHeader';

import { useStyles } from './StudentProfileStyle';
import moment from 'moment';

import ProfileIncompleteAlertInfo from '../../../components/ProfileIncompleteAlertInfo';

const StudentProfile = ({ studentId }: { studentId: number }) => {
  const classes = useStyles();

  function renderText() {
    return (
      <Box>
        <Typography variant="h5" component="h5" className={classes.noAccount}>
          no child account found
        </Typography>
      </Box>
    );
  }

  return (
    <Query<getProfileData>
      query={CHECK_STUDENT_PROFILE}
      fetchPolicy={'network-only'}
      variables={{ userId: studentId }}
    >
      {({ data, loading }) => {
        if (loading) return <LoaderVariant2 isSmallLoader />;
        if (!data) return <Box marginBottom={3}>{renderText()}</Box>;
          const schoolSize: string[] = [];
          const collegeType: string[] = [];
          if (data?.getProfileData?.smallSchool)
            schoolSize.push('Small (0 - 4,999)');
          if (data?.getProfileData?.mediumSchool)
            schoolSize.push('Medium (5,000 - 14,999)');
          if (data?.getProfileData?.largeSchool)
            schoolSize.push('Large (15,000 - 29,999)');
          if (data?.getProfileData?.extraLargeSchool)
            schoolSize.push('Extra Large (30,000+)');

          if (data?.getProfileData?.privateCollege) collegeType.push('Private');
          if (data?.getProfileData?.publicCollege) collegeType.push('Public');
        return (
          <Grid>
            <Grid>
              <ProfileIncompleteAlertInfo getProfileData={data?.getProfileData} />
            </Grid>
            <Grid container spacing={1} className={classes.personalInfo}>
              <Grid item md={5} sm={12} xs={12}>
                <StudentProfileHeader title="Location" />

                <Grid>
                  <Box display="flex" alignItems="center" mb="12px">
                    <Typography variant="body1">COUNTRY:</Typography>
                    <Typography variant="body2" className="secondaryText">
                      {data?.getProfileData?.country || '--'}
                    </Typography>
                  </Box>

                  <Box display="flex" alignItems="center" mb="12px">
                    <Typography variant="body1">HOMETOWN (CITY):</Typography>
                    <Typography variant="body2" className="secondaryText">
                      {data?.getProfileData?.city || '--'}
                    </Typography>
                  </Box>

                  <Box display="flex" alignItems="center" mb="12px">
                    <Typography variant="body1">STATE:</Typography>
                    <Typography variant="body2" className="secondaryText">
                      {data?.getProfileData?.state || '--'}
                    </Typography>
                  </Box>

                  <Box display="flex" alignItems="center" mb="54px">
                    <Typography variant="body1">ZIP code:</Typography>
                    <Typography variant="body2" className="secondaryText">
                      {data?.getProfileData?.zipCode || '--'}
                    </Typography>
                  </Box>
                </Grid>

                <StudentProfileHeader title="High School Information" />

                <Box mb="58px">
                  <Typography variant="body2" className="secondaryText">
                    {`${data.getProfileData?.highSchoolName || '--'} ${
                      data.getProfileData?.highSchoolState
                        ? '(' + data.getProfileData?.highSchoolState + ')'
                        : ''
                    }`}
                  </Typography>
                </Box>

                <StudentProfileHeader
                  title="Extracurricular Activities"
                  count={
                    data?.getProfileData?.studentExtraCurricularActivities?.length
                      ? `(${data?.getProfileData?.studentExtraCurricularActivities?.length})`
                      : '(0)'
                  }
                />

                <Grid className={classes.extraActivities}>
                  {data?.getProfileData?.studentExtraCurricularActivities?.map(
                    (data: any) => (
                      <Grid>
                        <Grid>
                          <Typography variant="h5">
                            {data?.title || '--'}
                          </Typography>
                          <Typography variant="h6">
                            Category:{' '}
                            {data?.extraCurricularActivity.category || '--'}
                          </Typography>
                          <Typography variant="h6">
                            {`${
                              data?.startedAt
                                ? moment(data?.startedAt).format('MM/YYYY')
                                : '--'
                            } - ${
                              data?.endedAt
                                ? moment(data?.endedAt).format('MM/YYYY')
                                : data?.currentlyActive && data?.startedAt
                                ? 'Present'
                                : '--'
                            } `}{' '}
                          </Typography>
                          {data?.leadershipExperience && data?.role && (
                            <Typography variant="h6">
                              {`Leadership (${data?.role})`}
                            </Typography>
                          )}

                          {data?.details && (
                            <Typography variant="h6">{data?.details}</Typography>
                          )}
                        </Grid>
                        <Divider />
                      </Grid>
                    )
                  )}
                </Grid>
              </Grid>

              <Grid item md={1} sm={12} xs={12}></Grid>

              <Grid item md={6} sm={12} xs={12}>
                <StudentProfileHeader title="GPA & Test Scores" />
                <Grid container spacing={2} className="gpaTestScores">
                  <Grid item md={5}>
                    <Box display="flex" alignItems="center" mb="12px">
                      <Typography variant="body1">GPA (Unweighted):</Typography>
                      <Typography variant="body2" className="secondaryText">
                        {data.getProfileData?.gpaNonWeighted || '--'}
                      </Typography>
                    </Box>

                    <Box display="flex" alignItems="center" mb="12px">
                      <Typography variant="body1">GPA (WEIGHTED):</Typography>
                      <Typography variant="body2" className="secondaryText">
                        {data.getProfileData?.gpaWeighted || '--'}
                      </Typography>
                    </Box>

                    <Box display="flex" alignItems="center" mb="12px">
                      <Typography variant="body1">GPA (OTHER):</Typography>
                      <Typography variant="body2" className="secondaryText">
                        {data.getProfileData?.gpa || '--'}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item md={7}>
                    <Box display="flex" alignItems="center" mb="12px">
                      <Typography variant="body1" className="labelWidth">
                        ACT SCORE (COMPOSITE):
                      </Typography>
                      <Typography variant="body2" className="secondaryText">
                        {data.getProfileData?.act || '--'}
                      </Typography>
                    </Box>

                    <Box display="flex" alignItems="center" mb="87px">
                      <Typography variant="body1" className="labelWidth">
                        SAT SCORE (COMPOSITE):
                      </Typography>
                      <Typography variant="body2" className="secondaryText">
                        {data.getProfileData?.sat || '--'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>

                <StudentProfileHeader
                  title="AP Courses"
                  count={
                    data?.getProfileData?.apCourses?.length
                      ? `(${data?.getProfileData?.apCourses?.length})`
                      : '(0)'
                  }
                />
                <ul>
                  <Grid container>
                    {data?.getProfileData?.apCourses?.map((course) => (
                      <Grid item md={6} sm={12} xs={12}>
                        <li>{course}</li>
                      </Grid>
                    ))}
                  </Grid>
                </ul>

                <StudentProfileHeader
                  title="IB Courses"
                  count={
                    data?.getProfileData?.IbCourses?.length
                      ? `(${data?.getProfileData?.IbCourses?.length})`
                      : '(0)'
                  }
                />
                <ul>
                  <Grid container>
                    {data?.getProfileData?.IbCourses?.map((course) => (
                      <Grid item md={6} sm={12} xs={12}>
                        <li>{course}</li>
                      </Grid>
                    ))}
                  </Grid>
                </ul>

                <StudentProfileHeader
                  title="Areas of Interest"
                  count={
                    data?.getProfileData?.areasOfInterest?.length
                      ? `(${data?.getProfileData?.areasOfInterest?.length})`
                      : '(0)'
                  }
                />
                <ul>
                  <Grid container>
                    {data?.getProfileData?.areasOfInterest?.map((area) => (
                      <Grid item md={6} sm={12} xs={12}>
                        <li>{area}</li>
                      </Grid>
                    ))}
                  </Grid>
                </ul>
                <StudentProfileHeader title="College Preferences" />
                <ul>
                  <Grid container>
                    <Box mb="10px" display="flex">
                      <Typography
                        variant="body1"
                        style={{ minWidth: '100px', marginRight: '10px' }}
                      >
                        Location(s):
                      </Typography>
                      <Typography variant="body2" className="secondaryText">
                        {`${
                          data.getProfileData?.location?.length
                            ? data.getProfileData?.location.join(', ')
                            : '--'
                        }`}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid container>
                    <Box mb="10px" display="flex">
                      <Typography
                        variant="body1"
                        style={{ minWidth: '100px', marginRight: '10px' }}
                      >
                        Size:
                      </Typography>
                      <Typography variant="body2" className="secondaryText">
                        {`${schoolSize.length ? schoolSize.join(', ') : '--'}`}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid container>
                    <Box mb="10px" display="flex">
                      <Typography
                        variant="body1"
                        style={{ minWidth: '100px', marginRight: '10px' }}
                      >
                        Types:
                      </Typography>
                      <Typography variant="body2" className="secondaryText">
                        {`${collegeType.length ? collegeType.join(', ') : '--'}`}
                      </Typography>
                    </Box>
                  </Grid>
                </ul>
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    </Query>
  );
};

export default StudentProfile;
