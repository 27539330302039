
import { Box, Card, Typography, Button, Grid } from '@material-ui/core';
// icons
import Info_Blue_Img from '../img/blue-info.svg';
// styles
import { useStyles } from './styles';

const ProfileIncompleteAlertInfo = ({ getProfileData }: { getProfileData: any}) => {
  
  const classes = useStyles();
  const getGradeYearInfo = () => {
    const currentGradeYear = process.env.REACT_APP_CURRENT_GRAD_YEAR || '2025';
    const { highSchoolYear } = getProfileData;
    return { currentGradeYear, highSchoolYear};
  }

  const hideBannerMessage = () => {
    const { currentGradeYear, highSchoolYear } = getGradeYearInfo();
    let showProfileInComplete = false;
    if (parseInt(highSchoolYear) === parseInt(currentGradeYear)) {
      // 'senior';
      showProfileInComplete = !getProfileData?.country || !getProfileData.city || !getProfileData.state || !getProfileData.zipCode || !getProfileData?.highSchoolName || !getProfileData?.highSchoolYear || (!getProfileData?.act && !getProfileData?.sat) || (!getProfileData?.gpa && !getProfileData?.gpaNonWeighted && !getProfileData.gpaWeighted) || (!getProfileData?.apCourses?.length && !getProfileData?.IbCourses?.length) || !getProfileData?.studentExtraCurricularActivities?.length;
    } else if (parseInt(highSchoolYear) >= (parseInt(currentGradeYear)+4)) {
      // 'middleSchool';
      showProfileInComplete = !getProfileData?.country || !getProfileData.city || !getProfileData.state || !getProfileData.zipCode || !getProfileData?.highSchoolName || !getProfileData?.highSchoolYear;
    } else if ((parseInt(highSchoolYear) === (parseInt(currentGradeYear)+2)) || (parseInt(highSchoolYear) === (parseInt(currentGradeYear)+3))) {
      // somophore freshman
      showProfileInComplete = !getProfileData?.country || !getProfileData.city || !getProfileData.state || !getProfileData.zipCode || !getProfileData?.highSchoolName || !getProfileData?.highSchoolYear || (!getProfileData?.gpa && !getProfileData?.gpaNonWeighted && !getProfileData.gpaWeighted) || (!getProfileData?.apCourses?.length && !getProfileData?.IbCourses?.length) || !getProfileData?.studentExtraCurricularActivities?.length;
    } else {
      // 'junior';
      showProfileInComplete = !getProfileData?.country || !getProfileData.city || !getProfileData.state || !getProfileData.zipCode || !getProfileData?.highSchoolName || !getProfileData?.highSchoolYear || !getProfileData?.act || !getProfileData?.sat || (!getProfileData?.gpa && !getProfileData?.gpaNonWeighted && !getProfileData.gpaWeighted) || (!getProfileData?.apCourses?.length && !getProfileData?.IbCourses?.length) || !getProfileData?.studentExtraCurricularActivities?.length;
    }

    return showProfileInComplete;
  }

  if (!hideBannerMessage()) {
    return null;
  }

  const getAreaOfInterest = () => {
    return !getProfileData?.areasOfInterest?.length ? <li>Areas of Interest</li> : null;
  }

  const getGpaHeading = () => {
    return <li>GPA & Test Scores</li>;
  }

  const getAborIbCources = () => {
    return !getProfileData?.apCourses?.length && !getProfileData?.IbCourses?.length ? <li>At least (1) AP or IB Course must be shared</li> : null;
  }

  const getExtraCurricularInfo = () => {
    return !getProfileData?.studentExtraCurricularActivities?.length ? <li>Extracurricular Activities</li> : null;
  }

  const getHighSchoolInfo = () => {
    return !getProfileData?.highSchoolName || !getProfileData?.highSchoolYear ? <li>High School Information</li> : null;
  }

  const getLocationText = () => {
    if (!getProfileData?.country || !getProfileData.city || !getProfileData.state || !getProfileData.zipCode) {
      let locationText = 'Location (';
      let locationFields = null;
      if (!getProfileData?.country) {
        locationFields = locationFields ? locationFields + ', country' : 'country';
      }
      if (!getProfileData?.city) {
        locationFields = locationFields ? locationFields + ', city' : 'city';
      }
      if (!getProfileData?.state) {
        locationFields = locationFields ? locationFields + ', state' : 'state';
      }
      if (!getProfileData?.zipCode) {
        locationFields = locationFields ? locationFields + ', zipCode' : 'zipCode';
      }
      locationText = locationText + locationFields + ')';
      return <li>{locationText}</li>;
    } else return null;
  }

  const getBannerText = () => {
    const { currentGradeYear, highSchoolYear } = getGradeYearInfo();
    let studentType = null;
    if (parseInt(highSchoolYear) === parseInt(currentGradeYear)) {
      studentType = 'senior';
    } else if (parseInt(highSchoolYear) === (parseInt(currentGradeYear)+1)) {
      studentType = 'junior';
    } else if (parseInt(highSchoolYear) === (parseInt(currentGradeYear)+2)) {
      studentType = 'somophore';
    } else if (parseInt(highSchoolYear) === (parseInt(currentGradeYear)+3)) {
      studentType = 'freshMan';
    } else if (parseInt(highSchoolYear) >= (parseInt(currentGradeYear)+4)) {
      studentType = 'middleSchool';
    }
  
    let returnValue = null;
    if (studentType === 'senior') {
      returnValue = <ul>
        { getLocationText() }
        { getHighSchoolInfo() }
        { (!getProfileData?.act && !getProfileData?.sat) || (!getProfileData?.gpa && !getProfileData?.gpaNonWeighted && !getProfileData.gpaWeighted) ? getGpaHeading() : null }
        { (!getProfileData?.act && !getProfileData?.sat) || (!getProfileData?.gpa && !getProfileData?.gpaNonWeighted && !getProfileData.gpaWeighted) ? <ul>
            { !getProfileData?.gpa && !getProfileData?.gpaNonWeighted && !getProfileData.gpaWeighted ? <li>At least (1) GPA type must be shared</li> : null }
            { !getProfileData?.act && !getProfileData?.sat ? <li>At least ACT or SAT score must be shared</li> : null }
          </ul> : null }
        { getAborIbCources() }
        { getExtraCurricularInfo() }
        { getAreaOfInterest() }
      </ul>;
    } else if (studentType === 'middleSchool') {
      returnValue = <ul>
        { getLocationText() }
        { getHighSchoolInfo() }
      </ul>;
    } else if (studentType === 'somophore' || studentType === 'freshMan') {
      returnValue = <ul>
        { getLocationText() }
        { getHighSchoolInfo() }
        { (!getProfileData?.gpa && !getProfileData?.gpaNonWeighted && !getProfileData.gpaWeighted) ? getGpaHeading() : null }
        { (!getProfileData?.gpa && !getProfileData?.gpaNonWeighted && !getProfileData.gpaWeighted) ? <ul>
            { !getProfileData?.gpa && !getProfileData?.gpaNonWeighted && !getProfileData.gpaWeighted ? <li>At least (1) GPA type must be shared</li> : null }
          </ul> : null }
        { getAborIbCources() }
        { getExtraCurricularInfo() }
        { getAreaOfInterest() }
      </ul>;
    } else {
      returnValue = <ul>
        { getLocationText() }
        { getHighSchoolInfo() }
        { (!getProfileData?.act || !getProfileData?.sat || (!getProfileData?.gpa && !getProfileData?.gpaNonWeighted && !getProfileData.gpaWeighted)) ? getGpaHeading() : null }
        { (!getProfileData?.act || !getProfileData?.sat || (!getProfileData?.gpa && !getProfileData?.gpaNonWeighted && !getProfileData.gpaWeighted)) ? <ul>
            { !getProfileData?.gpa && !getProfileData?.gpaNonWeighted && !getProfileData.gpaWeighted ? <li>At least (1) GPA type must be shared</li> : null }
            { !getProfileData?.act ? <li>ACT score must be shared</li> : null }
            { !getProfileData?.sat ? <li>SAT score must be shared</li> : null }
          </ul> : null }
        { getAborIbCources() }
        { getExtraCurricularInfo() }
        { getAreaOfInterest() }
      </ul>;
    }
    return returnValue;
  }

  return (
    <Card className={classes.alertInfoCard}>
      <Box display="flex">
        <Grid>
          <img src={Info_Blue_Img} alt="token" />
        </Grid>
        <Grid>
          <Typography variant="h5">Student profile is incomplete. Please reach out to the student and encourage them to complete as many profile fields as possible. The following profile fields have missing information:</Typography>
          <br />
          <Typography className={classes.incompleteProfile}>
            {getBannerText()}
          </Typography>

        </Grid>
      </Box>
    </Card>
  );
};

export default ProfileIncompleteAlertInfo;